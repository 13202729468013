<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
            :show="isLoading"
            rounded="sm"
        >
          <div class="m-2">
            <b-row class="">
              <b-col cols="12">
                <vue-good-table
                    mode="remote"
                    class="my-table"
                    :columns="columns"
                    :rows="mainData"
                    :pagination-options="paginationOptions"
                    :total-rows="totalRows"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    styleClass="my-table vgt-table striped bordered"
                >
                  <div slot="emptystate" style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào!
                  </div>
                  <template slot="table-row" slot-scope="props">
                    <!-- Column: Common -->
                    <span v-if="props.column.field === 'name'">
                      {{ '[' + props.row.code +'] ' + props.row.name }}
                    </span>
                    <span v-else> {{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị 1 đến </span>
                        <b-form-select
                            v-model="filter.itemsPerPage"
                            :options="itemsPerPageOptions"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap">của {{ mainData.length }} bản ghi</span>
                      </div>
                      <div>
                        <b-pagination
                            :value="1"
                            :total-rows="totalRows"
                            :per-page="filter.itemsPerPage"
                            class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BCard, BCol, BContainer, BOverlay, BPagination, BRow, BFormSelect,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'

export const Attr = Object.freeze({
  store: 'subject',
})
export default {
  name: 'InfoStudentRegisterSubject',
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BPagination,
    BRow,
    BFormSelect,
    VueGoodTable,
  },
  props: {
    dataSend: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        studentId: this.$route.params.studentId,
        courseSemesterId: this.$route.params.courseSemesterId,
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      mainData: `${Attr.store}/subjects`,
      totalRows: `${Attr.store}/totalRows`,
    }),
    columns() {
      return [
        {
          label: '#',
          field: 'rowNum',
          width: '30px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tên học phần',
          field: 'name',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Khoa/Bộ môn',
          field: 'departmentName',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Lớp độc lập',
          field: 'creditClassCode',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số TC',
          field: 'totalCreditNum',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  async created() {
    this.isLoading = true
    try {
      if (this.dataSend.courseSemesterId && this.dataSend.studentId) {
        this.filter.studentId = this.dataSend.studentId
        this.filter.courseSemesterId = this.dataSend.courseSemesterId
      }
      await this.getMainData(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: `${Attr.store}/readSubjectsByCourseSemesterAndStudent`,
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      await this.getMainDataFromStore()
    },
  },
}
</script>
